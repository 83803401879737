import './App.css';
import Crickvista from './Components/cricketvista/cricketvista';

function App() {
  return (
    <div className="App">
   <Crickvista/>    
    </div>
  );
}

export default App;
