import React from "react";
import Button from "../Button";
import  Feature from "../Feature"
const Crickvista = () => {
  return (
    <div className="relative w-full">
      {/* Fixed Background Image Section */}
      <div className="fixed top-0 left-0 w-full h-screen -z-10">
        <img
          className="w-full h-full object-cover"
          src="/ball ma.jpg"
          alt="Cricket Poster"
        />
      </div>

      {/* Overlay Section */}
      <div className="absolute top-0 left-0 w-full h-screen flex items-center bg-black opacity-70 justify-center text-white">
        <div className="text-center">
          <h1 className="text-6xl font-bold">CricketVista</h1>
          <p className="text-xl font-semibold mt-2">
            Immerse Yourself in the World of Fantasy Cricket
          </p>
          <div className="flex justify-center mt-4">
            <button
              className="bg-gray-100 animate-bounce text-black px-6 font-semibold py-2 flex items-center gap-3 mt-4 rounded-md"
              onClick={() => window.open("https://wa.link/indraads", "_blank")}
            >
              Reserve Your Spot
            </button>
          </div>
        </div>
      </div>

      {/* Spacer to push content below the fixed image */}
      <div className="h-screen"></div>
      <Feature />

      <div className="w-full h-auto flex flex-col items-center justify-center bg-yellow-300 p-10 space-y-4 text-center">
        <h1 className="text-4xl font-bold text-gray-700">About CricketVista</h1>
        <p className="text-lg max-w-2xl text-gray-700">
          CricketVista offers an exciting, skill-based fantasy experience that
          brings fans closer to the sport. Whether you're a seasoned cricket fan or
          new to fantasy sports, enjoy a world of thrilling challenges and exclusive
          experiences.
        </p>
        <Button label="Learn more" />
      </div>

      <div
        className="w-full h-[40vh]  bg-center bg-cover bg-no-repeat  pt-20 text-center"
        style={{ backgroundImage: "url('/ball image.avif')" }}
      >
        <h1 className="text-white font-bold text-5xl">
          Connect with CricketVista
        </h1>
        <p className="text-gray-100 text-xl mt-4">
          If you have any queries, feedback, or need assistance, our team is available 24/7 to support you. Get in touch now!
        </p>
        <Button label={"Learn More"} />
      </div>

      {/* Footer Section */}
      <div className="w-full h-[40vh] bg-gray-800 text-white pt-20 text-center">
        <h1 className="text-lg md:text-xl font-semibold">
          Disclaimer: This platform is designed for users aged 18 and above. If you
          reside in any restricted regions, please exit the site immediately. Be cautious
          of fraudsters, as we only conduct transactions via WhatsApp. We exclusively
          focus on fantasy sports and do not involve real money transactions.
        </h1>
        <p className="mt-4 text-sm">Copyright © 2024 CricketVista</p>
      </div>
    </div>
  );
};

export default Crickvista;
